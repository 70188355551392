<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.messaging', 1, true) }}
    </hero-bar>
    <section class="section is-main-section capitalize">
      <b-button tag="router-link"
                :to="{name: 'messaging.create'}"
                type="is-primary" icon-left="plus">{{ $getTranslation('form.general.create') }} {{ $getTranslation('form.message.label') }}</b-button>
    </section>

    <section class="buttons capitalize">
      <b-button
        class="is-small"
        v-for="action in actions"
        :key="action"
        @click="mails(action)"
      >
        {{ $getTranslation('messaging.' + action) }}
      </b-button>
    </section>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced" icon="account-multiple">

        <div v-if="!loading && error === null">
          <!-- TODO - add hasAccess to table action buttons -->
          <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :viewRoute="viewRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
          />

        </div>
        <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>

    </section>
  </div>
</template>

<script>
import { ApiMessaging } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'app-messaging',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },

  data () {
    return {
      endpoint: ApiMessaging + '/unread',
      // moduleName: this.$route.meta.type,
      viewRoute: 'messaging.view',
      total: 0,
      page: 1,
      actions: ['unread', 'sent', 'received'],
      columns: {
        'from':'form',
        'to': 'form',
        'subject': 'form',
        // 'message': 'form',
        'status': 'form',
        'created':'general'
      }

    }
  },

  computed: {
    //
  },

  methods: {
    mails (key) {
      this.endpoint = ApiMessaging + '/' + key
      this.fetchPaginatedItems();
    }
  },

  created () {
    this.fetchPaginatedItems ()
    // console.log('Messaging list created', this.$route.name)
  },
}
</script>
